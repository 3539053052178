/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';

/**
 * Internal dependencies.
 */
import PostContent from '@components/post-content';
import Image from '@components/image';

import Layout from '@components/layout';
import SEO from "@components/seo";
import RelatedPodcast from '@components/podcasts/article';
import RelatedPodcastList from '@components/podcasts/podcasts-list/podcasts-list';

import { getOgImage } from "@utils/functions";

import './style.scss';
import classNames from 'classnames';

/**
 * Webinar Single Post.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const PodcastSingle = (props) => {
	const { pageContext } = props;
	const {
		id,
		title,
		contentRaw,
		content,
		seo,
		uri,
		// pageMeta,
		posts,
		reusableBlocks,
		featuredImage,
		participants
	} = pageContext;

	// const [recentPodcasts, setRecentPodcasts] = useState([]);

	const openGraphImage = getOgImage(seo) || featuredImage?.node?.guid;
	let thumbnail = false;
	let recentPodcasts = [];

	if (posts?.length) {

		let filtered = posts.filter(item => item.id !== id).sort((item1, item2) => {
			const date1 = new Date(item1.date);
			const date2 = new Date(item2.date);
			return date1.getTime() - date2.getTime()
		})
		recentPodcasts = filtered.slice(0, 2);
	}

	return (
		<Layout title={title} uri={uri} theme={'dark'} className="page">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<PostContent content={content} contentRaw={contentRaw} reusableBlocks={reusableBlocks} />

			{participants?.guests?.length > 0 || participants?.hosts?.length > 0 ? <div className='guests-and-hosts-container'>
				{participants?.guests && <div className='guests'>
					<h2 className='guests-title'>Guest{participants.guests.length > 1 && "s"}</h2>
					{participants.guests.map((guest, key) => (<div key={key} className={classNames(['guest-container', { 'margin-top': key > 0 }])}>
						<div className='guest-portrait'>
							{guest?.featuredImage?.node?.sourceUrl && <img src={guest.featuredImage.node.sourceUrl} width={guest.featuredImage.node.mediaDetails.width} height={guest.featuredImage.node.mediaDetails.height} alt={guest.featuredImage.node.altText || guest.title} title={guest.featuredImage.node.altText || guest.title} />}
							<div className='guest-proffesional-info'>
								<span className='guest-name'>{guest.title}</span>
								{guest?.guestInfo?.position && <span className='guest-position'>{guest.guestInfo.position}</span>}
							</div>
						</div>
						<p className='guest-bio'>
							{guest?.guestInfo?.bio && guest.guestInfo.bio}
						</p>
					</div>))}
				</div>}

				{participants?.hosts && <div className='hosts'>
					<h2 className='hosts-title'>Host{participants.hosts.length > 1 && "s"}</h2>
					{participants.hosts.map((host, key) => (<div key={key} className='host-container'>
						<div className='host-portrait'>
							{host?.featuredImage?.node?.sourceUrl && <img src={host.featuredImage.node.sourceUrl} width={host.featuredImage.node.mediaDetails.width} height={host.featuredImage.node.mediaDetails.height} alt={host.featuredImage.node.altText || host.title} title={host.featuredImage.node.altText || host.title} />}
						</div>
						<div className='host-info'>
							<span className='host-name'>{host.title}</span>
							{host?.hostInfo?.position && <span className='host-position'>{host.hostInfo.position}</span>}
						</div>
					</div>))}
				</div>}
			</div> : null}
			{recentPodcasts && recentPodcasts?.length > 0 && <>
				<h3 className='more-episodes-title'>More Episodes</h3 >
				<RelatedPodcastList items={recentPodcasts} noWide />
			</>}
		</Layout>
	)
};

PodcastSingle.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default PodcastSingle;
