/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import ArrowBigRight from './../../icons/long-arrow-right-recources';
import Image from './../../image/index';

import './style.scss';
import { sanitize } from '../../../utils/functions';

/**
 * Resource Article Component.
 *
 * @param {Object} data Component props.
 * @param {String} type Resource type.
 *
 * @return {jsx}
 */



const PodcastArticle = ({ data, animate, isWide }) => {

	const { title, featuredImage, excerpt, date } = data;
	const color = '#000';
	let buttonName = 'Read more';
	const containerClassName = classnames(
		'article-card',
		{ 'animated slideInUp': animate },
		{ 'article-card--wide': isWide }
	);

	if (
		data?.custom_fields?.guideDetailsGroup?.downloadLink
		&& data?.custom_fields?.guideDetailsGroup?.gaAction
	) {
		buttonName = data?.custom_fields?.guideDetailsGroup?.gaAction;
	}

	const dateObj = new Date(date);
	const day = dateObj.getDate()
	const month = dateObj.getMonth();

	const formattedDate = `${day < 10 ? `0${day}` : day}.${month + 1 < 10 ? `0${month + 1}` : month}.${dateObj.getFullYear().toString().slice(-2)}`;

	return (
		<article className={`${containerClassName}`}>
			<div className="article__header">
				{
					!isEmpty(featuredImage) ? (
						<figure className="article__figure">
							<Image {...featuredImage.node} lazy={false} />
						</figure>
					) : null
				}

			</div>

			<div className="article-card__footer">
				<div className='article-card__footer-top'>
					<span className="article-card__term">{formattedDate}</span>
					<h2 className="article-card__title">
						{title}
					</h2>
					<div className="article-card__content" />
				</div>
				<div className='article-card__meta'>
					<div className="article__read-more">
						<span className="article__read-more-text">{buttonName}</span> {ArrowBigRight(color)}
					</div>
				</div>

			</div>
		</article>
	);
};

PodcastArticle.propTypes = {
	data: PropTypes.object,
};

PodcastArticle.defaultProps = {
	data: {
		title: '',
		uri: '',
		featuredImage: {
			node: {}
		}
	}
};

export default PodcastArticle;
