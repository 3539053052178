/**
 * External dependencies.
 */
import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classnames from 'classnames';
import { Link } from 'gatsby';

/**
 * Internal dependencies.
 */
// import ArticleCard from '../article-card';
import PodcastsArticle from '../article/index';

import './style.scss';

/**
 * PodcastsList Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */

const SORT_BY = {
    DATE: 'date',
    NEWEST_FIRST: 'newest_first',
    OLDEST_FIRST: 'oldest_first'
}


const PodcastsList = forwardRef(({ items, noWide, animate = true, className, refIndex, showFilters }, ref) => {

    if (isEmpty(items) || !items.length) return null;

    const [size, setSize] = useState(4);
    const [sortby, setSortby] = useState('false');
    const [filter, setFilter] = useState('false');

    const handleLoadMore = () => {
        setSize(size + 5);
    }

    const containerClass = classnames('podcasts-list', 'blog-list', className);


    const filteredList = (items) => {

        const filtered = items.filter((item) => {
            if (filter !== 'false') {
                if (item.podcastCategories?.nodes?.length > 0) {
                    return item.podcastCategories.nodes.some(({ id }) => id === filter)
                }
            } else {
                return item;
            }
        });
        const sorted = filtered.sort((item1, item2) => {
            const date1 = new Date(item1.date);
            const date2 = new Date(item2.date);

            if (sortby === SORT_BY.NEWEST_FIRST) {
                return date2.getTime() - date1.getTime();
            } else if (sortby === SORT_BY.OLDEST_FIRST) {
                return date1.getTime() - date2.getTime();
            }
            return 0;
        });

        return sorted || filtered;
    }

    const filters = {};
    const filterArray = [];
    const sortBy = [
        { value: SORT_BY.NEWEST_FIRST, name: "Newest first" },
        { value: SORT_BY.OLDEST_FIRST, name: "Oldest first" },
    ]

    items.map(item => {
        if (item?.podcastCategories?.nodes?.length > 0) {
            const categories = item.podcastCategories.nodes;
            categories.map(category => {
                if (!filters[category.id]) {
                    filters[category.id] = { name: category.name };
                    filterArray.push(category)
                }
            })
        }
    });

    const handleFilter = (e) => {
        setFilter(e.target.value);
        if (e.target.value !== 'false') {
            setSortby(SORT_BY.NEWEST_FIRST)
        }
    }

    const handleSortBy = (e) => {
        setSortby(e.target.value);
    }

    return (
        <div id="episodes">
            <h2 className='episodes-title'>Episodes</h2>
            {showFilters && <div className='podcasts-filters'>
                <select onChange={handleFilter} value={filter}>
                    <option value="false">Season</option>
                    {filterArray.map((filter, key) => <option key={key} value={filter.id}>{filter.name}</option>)}
                </select>
                <select onChange={handleSortBy} value={sortby}>
                    <option value="false">Sort by</option>
                    {sortBy.map((sortOption, key) => <option key={key} value={sortOption.value}>{sortOption.name}</option>)}
                </select>
            </div>}
            <div className={containerClass}>
                {filteredList(items).slice(0, size).map((item, index) => {
                    const isWide = noWide ? false : 0 === index % 3;
                    const listClass = classnames('blog-list__item', { 'blog-list__item--wide': isWide });
                    const linkClass = "blog-list__card-link";

                    return (
                        <div ref={index === refIndex ? ref : null} key={`${item.id}-${index}`} className={listClass}>
                            <Link className={linkClass} to={item.uri}  >
                                <PodcastsArticle
                                    data={item}
                                    type={item.contentTypeName}
                                    isWide={isWide}
                                    animate={animate}
                                />
                            </Link>
                        </div>
                    );
                })}
            </div>
            {filteredList(items).length > size && <div className={classnames(['load-more', ''])}><button className="load-more__button icon-button" onClick={() => handleLoadMore()}>Load more</button></div>}
        </div>
    );
});

PodcastsList.propTypes = {
    items: PropTypes.array,
    animate: PropTypes.bool,
    className: PropTypes.string,
    refIndex: PropTypes.number
};

PodcastsList.defaultProps = {
    items: [],
    animate: true
};

export default PodcastsList;
